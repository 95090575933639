<template>
  <div class="urlToScan">
    <canvas :id="id"></canvas>
  </div>
</template>
<script setup>
import QRCode from "qrcode";
import { nextTick, watch, ref } from "vue";
import { uuid } from 'vue-uuid';
const props = defineProps({
  url: {
    type: String
  },
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 200
  }
});
const id = ref(uuid.v4());
const setCode = () => {
  const canvas = document.getElementById(id.value);
  const link = props.url;
  console.log(link)
  QRCode.toCanvas(
    canvas,
    decodeURIComponent(link),
    { width: props.width, height: props.height },
    error => {
      if (error) {
        console.error(error);
      } else {
        console.log("二维码生成成功！");
      }
    }
  );
};
watch(
  () => props.url,
  async() => {
    await nextTick();
    setCode();
  }, {
    immediate: true
  }
);
</script>

<style lang="less" scoped>
.urlToScan {
}
</style>
