export default {
    // 辅具申请
    applyUrl: "https://zhenxinyihu.com:8889/#/",
    // 评估系统
    assessUrl: require('@/assets/images/pgxt-scan.jpg'),
    // 用户注册
    userRegisterUrl: "https://zhenxinyihu.com:8889/#/pages/users/login/index",
    // 商户注册
    merchantUrl: "https://zhenxinyihu.com:8889/#/pages/user/index",
    // 商城入口
    shoptUrl: "https://zhenxinyihu.com:8889/#/",
}